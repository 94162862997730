import { DatePicker, Input, message, Select } from "antd";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import back from "../images/back.svg";
import InputMask from "react-input-mask";
import phone from "../images/phone.svg";
import lock from "../images/lock.svg";
import check from "../images/check.svg";
import Ripples from "react-ripples";
import {
  LoadingOutlined,
  CaretDownOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import MyButton from "../components/MyButton";
import { Option } from "antd/lib/mentions";
import FetchApi from "../api/FetchApi";
import moment from "moment";

const Replace = x => {
  let y = x.replace("+", "");
  y = y.replace(")", "");
  y = y.replace("(", "");
  y = y.replace(/ +/g, "");
  y = x.replace(/[^0-9]/g, "");
  return y;
};

const SignUp = () => {
  const [crediantials, setCredientials] = useState({});
  const [parol2, setParol2] = useState();
  const [loading, setLoading] = useState(false);
  const [band, setBand] = useState(0);

  const history = useHistory();
  const handleFocus = e => {
    if (e.target.parentElement.classList.length == 2) {
      e.target.parentElement.parentElement.classList.add("oranged");
    } else {
      e.target.parentElement.classList.add("oranged");
    }
  };
  const handleBlur = e => {
    if (e.target.parentElement.parentElement.classList.length > 1) {
      e.target.parentElement.parentElement.classList.remove("oranged");
    }

    if (e.target.parentElement.classList.length > 1) {
      e.target.parentElement.classList.remove("oranged");
    }
  };

  const handleChange = e => {
    setCredientials({
      ...crediantials,
      [e.target.name]: e.target.value,
    });
  };

  const handleTelefon = async e => {
    if (Replace(e.target.value).length == 12) {
      setLoading(true);
      let data = {
        telefon: Replace(e.target.value),
      };
      console.log(data);
      const res = await FetchApi(
        "/telcheck",
        "GET",
        null,
        Replace(e.target.value)
      );
      if (res && res.data.length > 0) {
        setBand(1);
      } else {
        setCredientials({
          ...crediantials,
          telefon: Replace(e.target.value),
        });
        setBand(2);
      }

      setLoading(false);
    } else {
      setCredientials({
        ...crediantials,
        telefon: null,
      });
      setBand(0);
    }
  };

  const handleSubmit = async () => {
    if (crediantials.parol == parol2) {
      const obj = {
        fam_ism:
          crediantials.fam && crediantials.ism
            ? crediantials.fam + " " + crediantials.ism
            : null,
        telefon: crediantials.telefon ? crediantials.telefon : null,
        telefon_2: crediantials.telefon_2 ? crediantials.telefon_2 : null,
        vil_id: crediantials.vil_id ? crediantials.vil_id : null,
        manzil: crediantials.manzil ? crediantials.manzil : null,
        birth: crediantials.birth ? crediantials.birth : null,
        parol: crediantials.parol ? crediantials.parol : null,
      };

      setLoading(true);
      const res = await FetchApi("/ustalar", "POST", obj);
      if (res && res.status == 200) {
        message.success("saqlandi");
        history.push("/");
      }
      setLoading(false);
    } else {
      message.warning("Парол мос тушмади");
    }
  };
  return (
    <div className="signup">
      <Link to="/">
        <div className="back_icon">
          <img src={back}></img>
        </div>
      </Link>
      <div>
        <h3 style={{ fontWeight: "550", color: "#4F4F4F", marginTop: "30px" }}>
          Рўйхатдан ўтиш
        </h3>
        <form>
          <div style={{ display: "grid", gridGap: "30px" }}>
            <div className="single">
              <Input
                onBlur={handleBlur}
                onFocus={handleFocus}
                placeholder="Исм"
                name="ism"
                onChange={handleChange}
              />
            </div>
            <div className="single">
              <Input
                onBlur={handleBlur}
                onFocus={handleFocus}
                placeholder="Фамилия"
                name="fam"
                onChange={handleChange}
              />
            </div>
            <div className="single">
              <span className="text">Туғилган сана (кун / ой / йил)</span>
              <DatePicker
                format="DD/MM/YYYY"
                suffixIcon={null}
                bordered={false}
                placeholder="--/--/----"
                onChange={v =>
                  setCredientials({
                    ...crediantials,
                    birth: moment(v).format("YYYY-MM-DD"),
                  })
                }
              />
            </div>
            <div className="triple">
              <img src={phone}></img>
              <InputMask
                mask="+\9\98 (99) 999 99 99"
                maskChar="-"
                alwaysShowMask={true}
                onFocus={handleFocus}
                onBlur={handleBlur}
                name="telefon"
                onChange={handleTelefon}
              />
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {band == 2 ? <img src={check}></img> : null}
                {loading ? (
                  <LoadingOutlined
                    style={{ color: "var(--main-color)" }}
                  ></LoadingOutlined>
                ) : null}
                {band == 1 ? (
                  <ExclamationCircleFilled
                    style={{ color: "orange", fontSize: "16px" }}
                  ></ExclamationCircleFilled>
                ) : null}
              </div>
            </div>
            <div className="single">
              <InputMask
                mask="+\9\98 (99) 999 99 99"
                maskChar="-"
                alwaysShowMask={false}
                placeholder="Телефон рақам 2"
                onChange={handleChange}
                name="telefon_2"
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
            </div>
            <div className="single">
              <Select
                onBlur={handleBlur}
                onFocus={handleFocus}
                placeholder="Вилоятни танланг"
                onChange={v =>
                  handleChange({ target: { name: "vil_id", value: v } })
                }
                suffixIcon={
                  <CaretDownOutlined
                    style={{ fontSize: "16px", color: "var(--main-color)" }}
                  ></CaretDownOutlined>
                }
              >
                {vils.map(v => (
                  <Option key={v.id}>{v.nomi}</Option>
                ))}
              </Select>
            </div>
            <div className="single">
              <Input
                onBlur={handleBlur}
                onFocus={handleFocus}
                placeholder="Манзил"
                name="manzil"
                onChange={handleChange}
              />
            </div>
            <div className="input_container">
              <img src={lock}></img>
              <Input.Password
                onBlur={handleBlur}
                onFocus={handleFocus}
                name="parol"
                onChange={handleChange}
                placeholder="Парол"
                iconRender={visible =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </div>
            <div className="input_container">
              <img src={lock}></img>
              <Input.Password
                onBlur={handleBlur}
                onFocus={handleFocus}
                placeholder="Парол"
                onChange={e => setParol2(e.target.value)}
                visibilityToggle={false}
              />
            </div>
          </div>
          <div style={{ marginTop: "56px" }}>
            <Ripples onClick={handleSubmit}>
              <MyButton>Давом этиш</MyButton>
            </Ripples>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUp;

export const vils = [
  {
    id: 1,
    nomi: "Farg`ona viloyati",
  },
  {
    id: 2,
    nomi: "Andijon viloyati",
  },
  {
    id: 3,
    nomi: "Buxoro viloyati",
  },
  {
    id: 4,
    nomi: "Jizzax viloyati",
  },
  {
    id: 5,
    nomi: "Qashqadaryo viloyati",
  },
  {
    id: 6,
    nomi: "Navoiy viloyati",
  },
  {
    id: 7,
    nomi: "Namangan viloyati",
  },
  {
    id: 8,
    nomi: "Samarqand viloyati",
  },
  {
    id: 9,
    nomi: "Surxondaryo viloyati",
  },
  {
    id: 10,
    nomi: "Sirdaryo viloyati",
  },
  {
    id: 11,
    nomi: "Toshkent viloyati",
  },
  {
    id: 12,
    nomi: "Qoraqalpog`iston Respublikasi",
  },
  {
    id: 13,
    nomi: "Xorazm viloyati",
  },
  {
    id: 14,
    nomi: "Toshkent shahri",
  },
];
